<template>
  <div>
    <stepper :value="1" />
    <v-row class="name mb-n12 justify-center">
      <v-col lg="6">
        <v-text-field
          class="v-text-field"
          v-model="data.name"
          :label="$t('exercise_name')"
          append-inner-icon="mdi-map-marker"
          autofocus
          outlined
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="name mb-n12 justify-center">
      <v-col lg="6">
        <v-text-field
          class="text "
          type="text"
          v-model="data.serial"
          :label="$t('id')"
          append-inner-icon="mdi-map-marker"
          outlined
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="name mb-n12 justify-center">
      <v-col lg="6">
        <v-textarea
          v-model="data.description"
          outlined
          :label="$t('description')"
          rows="4"
          row-height="15"
          shaped
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="name mb-n12 justify-center">
      <v-col lg="6">
        <v-autocomplete
          :search-input.sync="searchTerm"
          v-model="data.age_group_id"
          :label="$t('age group')"
          item-text="name"
          item-value="id"
          outlined
          validate-on-blur
          deletable-chips
          chips
          :loading="ageGroup.getState.loading"
          :items="ageGroupItems"
          :menu-props="{ closeOnClick: true }"
        >
          <template v-if="!stopInfinityScroll" v-slot:append-item>
            <div
              v-intersect="onIntersectAge"
              class="pa-4 teal--text justify-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template> </v-autocomplete
      ></v-col>
    </v-row>
    <v-row class="name mb-n12 justify-center">
      <v-col lg="6">
        <v-autocomplete
          deletable-chips
          item-text="name"
          item-value="id"
          v-model="data.skills"
          chips
          :label="$t('skilles')"
          :items="skillsItems"
          multiple
          outlined
          ref="autocomplete"
          :menu-props="{ closeOnClick: true }"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="justify-center mb-4">
      <v-col lg="2"
        ><span>{{ $t("exercise_type") }}</span></v-col
      >
      <v-col cols="12" sm="7" md="6" lg="4">
        <v-sheet elevation="10" rounded="xl">
          <div class="pa-4">
            <v-chip-group
              v-model="data.type"
              active-class="primary--text"
              column
            >
              <v-chip
                v-for="(exercise, index) in types"
                :key="index"
                :value="exercise.value"
              >
                {{ $t(exercise.text) }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <btns :disable="validate" :action="nextStep" :back="previousStep" />
  </div>
</template>
<script>
import { Skilles } from "@/store/exercise/skilles";
import { ageGroup } from "@/store/exercise/age-group";
export default {
  props: ["fillData", "types", "skillsItems", "ageItems"],
  data() {
    return {
      Skilles,
      ageGroup,
      agePage: 0,
      searchTerm: "",
      ageGroupItems: [],

      data: {
        name: null,
        serial: null,
        description: null,
        age_group_id: null,
        type: null,
        skills: [],
      },
    };
  },
  methods: {
    nextStep() {
      this.$emit("getData", this.data);
    },
    previousStep() {
      this.$router.go(-1);
    },
    async onIntersectAge() {
      this.agePage += 1;
      await ageGroup.get({ page: this.agePage, name: this.searchTerm });
      this.ageGroupItems = this.ageGroupItems.concat(this.ageItems);
    },
  },
  computed: {
    validate() {
      if (
        (this.data.name == null) |
        (this.data.name == "") |
        ((this.data.serial == null) | (this.data.serial == "")) |
        ((this.data.description == null) | (this.data.description == "")) |
        ((this.data.age_group_id == null) | (this.data.age_group_id == "")) |
        (this.data.skills.length == 0) |
        (this.data.type == null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    stopInfinityScroll() {
      return ageGroup.tableData.data.length == 0;
    },
  },
  created() {
    this.onIntersectAge();
  },
  watch: {
    searchTerm(val) {
      if (ageGroup.getState.loading) return;
      setTimeout(() => {
        this.agePage = 1;
        ageGroup.get({ page: this.agePage, name: val });
      }, 500);
    },
  },
};
</script>
