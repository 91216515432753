<template>
  <div ref="pageContainer" class="add-exercise">
    <step1
      v-bind:style="{ display: step == 1 ? 'block' : 'none' }"
      @getData="setStep1Data($event)"
      @goBack="$router.go(-1)"
      :types="typesItems"
      :skillsItems="skillItems"
      :ageItems="ageGroupItems"
    />
    <v-expand-transition>
      <step2
        v-bind:style="{ display: step == 2 ? 'block' : 'none' }"
        @getData="setStep2Data($event)"
        @goBack="step = 1"
        :type="data.type"
      />
    </v-expand-transition>
    <step3
      v-bind:style="{ display: step == 3 ? 'block' : 'none' }"
      @goBack="step = 2"
      @getData="postData($event)"
    />
  </div>
</template>
<script>
import { Exercise } from "@/store/exercise/add";
import { Skilles } from "@/store/exercise/skilles";
import { ageGroup } from "@/store/exercise/age-group";
import step1 from "./step1.vue";
import step2 from "./step2.vue";
import step3 from "./step3.vue";
import Swal from "sweetalert2";
export default {
  beforeRouteLeave(to, from, next) {
    if (this.canGoBack) {
      next();
    } else {
      if (this.step != 3) {
        next(false);
        // Implement your custom behavior here
        // For example, show a warning message and ask the user to confirm
        const confirmed = confirm("Are you sure you want to leave this page?");
        if (confirmed) {
          // Allow the user to navigate back after confirming
          this.canGoBack = true;
          next();
        }
      } else {
        this.canGoBack = true;
        return;
      }
    }
  },
  components: {
    step1,
    step2,
    step3,
  },
  data() {
    return {
      Exercise,
      Skilles,
      ageGroup,
      step: 1,
      canGoBack: false,
      data: {},
    };
  },
  methods: {
    showConfirmationDialog() {
      this.$refs.confirmationDialog.showConfirmationDialog();
    },
    setStep1Data(data) {
      this.data = data;
      this.step = 2;
    },
    setStep2Data(data) {
      Object.assign(this.data, { schema: data });
      this.step = 3;
    },
    async postData(data) {
      Object.assign(this.data, { background_sound: data.background_sound });
      Object.assign(this.data, { max_grade: data.max_degree });
      Object.assign(this.data, { grade_to_pass: data.degree_to_pass });
      await Exercise.post(this.data);
      this.$router.push("/Exercise/table");
    },
  },
  computed: {
    skillItems() {
      return Skilles.tableData.data;
    },
    ageGroupItems() {
      return ageGroup.tableData.data;
    },
    typesItems() {
      return Exercise.types;
    },
  },

  mounted() {
    Skilles.get({ page: 1 });
    ageGroup.get({ page: 1 });
  },
};
</script>
