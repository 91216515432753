<template>
  <div transition="fade-transition">
    <stepper :value="2" :complete="1" />
    <component
      @checkDisable="checkDisable($event)"
      @getData="getData($event)"
      :is="typeMapper(type)"
    ></component>
    <btns :disable="false" :action="nextStep" :back="previousStep" />
  </div>
</template>
<script>
import videoExercise from "../types/video/index.vue";
import crossExercise from "../types/cross-word/index.vue";
import audioRecord from "../types/audio-record/index.vue";
import chooseAnswer from "../types/choose-answer/index.vue";
import coloringGame from "../types/coloring-game/index.vue";
import fillSpaces from "../types/fill-spaces/index.vue";
import linkGame from "../types/link-game/index.vue";
import mazeGame from "../types/maze-game/index.vue";
import memoryGame from "../types/memory-game/index.vue";
import powerPoint from "../types/power-point/index.vue";
import puzzle from "../types/puzzle/index.vue";
import rankGame from "../types/rank-game/index.vue";
import rearrange from "../types/rearrange/index.vue";
import trueFales from "../types/true-false/index.vue";
export default {
  props: ["type", "fillData"],

  components: {
    videoExercise,
    crossExercise,
    audioRecord,
    chooseAnswer,
    coloringGame,
    fillSpaces,
    linkGame,
    mazeGame,
    memoryGame,
    powerPoint,
    puzzle,
    rankGame,
    rearrange,
    trueFales,
  },
  data() {
    return {
      data: null,
      disable: true,
    };
  },
  methods: {
    typeMapper(type) {
      const exercise = new Map([
        ["video", "videoExercise"],
        ["cross_words", "crossExercise"],
        ["voice_record", "audioRecord"],
        ["choose", "chooseAnswer"],
        ["coloring", "coloringGame"],
        ["fill_gaps", "fillSpaces"],
        ["match", "linkGame"],
        ["maze", "mazeGame"],
        ["memory", "memoryGame"],
        ["presentation", "powerPoint"],
        ["puzzle", "puzzle"],
        ["categorize", "rankGame"],
        ["reorder", "rearrange"],
        ["true_or_false", "trueFales"],
      ]);
      return exercise.get(type);
    },
    nextStep() {
      this.$emit("getData", this.data);
    },

    previousStep() {
      this.$emit("goBack");
    },
    getData(data) {
      this.data = data;
    },
    checkDisable(check) {
      this.disable = check;
    },
  },
  mounted() {
    if (this.fillData) {
      this.data = this.fillData;
    }
    this.$exercise.exerciseName = this.type;
  },
  destroyed() {
    this.$exercise.exerciseName = null;
  },
};
</script>
