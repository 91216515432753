<template>
  <v-container style="display: block;">
    <stepper :value="3" :complete="2" />
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :disable="true"
      label="Choose a background sound clip"
    />
    <v-row class="name  mb-n4 justify-center">
      <v-col lg="2">
        <v-text-field
          class="text"
          type="number"
          v-model="data.max_degree"
          :label="$t('max_degree')"
          append-inner-icon="mdi-map-marker"
          outlined
        ></v-text-field
      ></v-col>
      <v-col lg="2">
        <v-text-field
          class="text "
          type="number"
          v-model="data.degree_to_pass"
          :label="$t('degree_to_pass')"
          append-inner-icon="mdi-map-marker"
          outlined
        ></v-text-field
      ></v-col>
    </v-row>

    <btns
      :disable="false"
      :action="finalStep"
      :back="previousStep"
      :loader="loader"
    />
  </v-container>
</template>
<script>
import { Exercise } from "@/store/exercise/add";
export default {
  data() {
    return {
      items: 2,
      data: {
        background_sound: null,
        max_degree: null,
        degree_to_pass: null,
      },
      Exercise,
    };
  },
  methods: {
    setBaseQuestion(data) {
      this.data.background_sound = data;
    },
    finalStep() {
      this.$emit("getData", this.data);
    },
    previousStep() {
      this.$emit("goBack");
    },
  },
  computed: {
    loader() {
      return Exercise.postState.loading;
    },
    validate() {
      return (
        !this.data.background_sound ||
        !this.data.max_degree ||
        !this.data.degree_to_pass ||
        Number(this.data.degree_to_pass) < 0 ||
        Number(this.data.max_degree) < 0 ||
        Number(this.data.degree_to_pass) > Number(this.data.max_degree)
      );
    },

    degreeToPassRules() {
      return [
        Number(this.data.max_degree) >= Number(this.data.degree_to_pass) ||
          Number(this.data.degree_to_pass) >= 0 ||
          Number(this.data.max_degree) >= 0 ||
          this.$t("degree_to_pass_cannot_be_greater_than_max_degree"),
      ];
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";

.add-box {
  width: 20%;
  height: 150px;
  margin: auto;
  border: 1px solid grey;
  cursor: pointer;
}
.icon {
  width: 50% !important;
  height: 50% !important;
  color: grey !important;
}
.drag-drob {
  height: 200px;
  border: 1px solid grey;
  margin: auto;
}
.display_style {
  height: 200px;
  border: 1px solid $myBlue;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
